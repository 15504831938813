import React from 'react'
import {
  Box,

  Grid,
  Typography,

} from "@material-ui/core";
import { createUseStyles } from 'react-jss'
const styles = createUseStyles({
  boximg: {
    backgroundImage: `url("images/footer.png")`,
    height: '10rem',
    marginTop: '10%',
  },
  text: {
    position: "absolute",
    left: '65px',
    fontWeight: '700 !important',
    fontSize: '24px !important',
    fontFamily: 'Oswald', color: "#FFFFFF", marginTop: '3rem !important'
  },
  gridleft: {
    position: "absolute",
    paddingTop: "7rem",

    display: 'flex',
    flexDirection: 'row',
    justifyContents: 'space-between'
  }
})
const Bottom = () => {
  const classes = styles();

  return (
    <div>

      <Box>
        <Grid xs={12} className={classes.boximg}>

          <Typography className={classes.text}>SOIL</Typography>
          <div style={{ position: 'absolute', marginTop: '5rem' }}><img src='images/Line 15.png' style={{ width: '100%' }}></img>
          </div>
          <Grid container xs={12} className={classes.gridleft}>
            <Grid xs={9}>
              <Typography style={{
                display: 'flex', marginLeft: '4rem', color: 'white'
              }}>Soil © 2022 (POLY-B1) | Donate </Typography>
            </Grid>
            <Grid container xs={3} style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "auto",
              justifyContent: "space-evenly", color: 'white'
            }}>
              <Grid xs={2}>
                <Typography style={{width:'max-content'}}>Terms and Condition</Typography>
              </Grid>
              <Grid xs={2}>
                <Typography style={{width:'max-content'}}>Privacy policy</Typography>
              </Grid>
            </Grid>
          </Grid>

        </Grid>

        <div>
        </div>
      </Box>
    </div>
  )
}

export default Bottom
