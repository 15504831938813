import * as React from "react";
import MenuIcon from "@material-ui/icons/Menu";
import { IconButton } from '@material-ui/core';
import { AppBar, Container, Box,Toolbar, Typography } from "@material-ui/core";
import { createUseStyles } from "react-jss";

const styles = createUseStyles({
  soil: {

    fontFamily: "Oswald !important",
    fontStyle: "normal",
    fontWeight: "700 !important",
    letterSpacing: ".3rem",
    color: "#1976D2 !important",
    textDecoration: "none",
    fontSize: "36px !important",
  },
 
});
const Navbar = () => {
  const classes = styles();
  return (
    <AppBar position="static">
      <Container
        maxWidth="xxl"
        style={{ backgroundColor: "#d3d3d3", width: "100%" }}
      >
        <Toolbar disableGutters style={{ margin: "0rem 4rem" }}>
          <Typography className={ classes.soil}
            variant="h6"
            noWrap
            component="a"
            href="/"
           
          >
            SOIL
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
              >
              <MenuIcon />
            </IconButton>
          </Box>

        
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Navbar;
