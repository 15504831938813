import React from 'react'
import TransactionDetails from "./TransactionDetails"
import { createUseStyles } from 'react-jss'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  makeStyles, Container,
  Box,
  Typography,
  Grid, Divider, Button

} from "@material-ui/core";
import Bottom from "./footer";
import { useHistory } from "react-router-dom";
const styles = createUseStyles({
  box: {
    border: "1px solid #e7eaf3",
    textAlign: "left",

  },
  outergrid: {
    border: '1px solid grey',
    //padding:'inherit !important'
  },
  text: {
    align: "left !important",
    marginLeft: "2%",
    marginTop: "1%",
    fontSize: "20px !important",
    fontWeight: "800 !important", color: ' #363636 !important',
  },
  matic: {
    backgroundColor: "rgba(119,131,143,.1)",
    position: "relative",
    padding: '0.3rem',
    letterSpacing: ".8px",
    //width: '8rem !important',
    fontSize: "12px !important", textAlign: "center !important"
  },
  maingrid: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    gap: '3rem',
  },
  box1: {

    "@media(max-width:1351px)": {
      width: "30rem !important",

      //marginLeft:'4rem'
    },

    "@media(min-width:1352px) and (max-width:1450px)": {

      width: "32rem !important",
    }
  },
  grid2: {

    "@media(max-width:1351px)": {
      height:'3.2rem'
    },
  },
  box2: {

    "@media(max-width:1351px)": {
      width: "30rem !important",
      //marginRight: '4rem',

    },
    "@media(min-width:1352px) and (max-width:1450px)": {
      margin: "0rem 0rem 6rem 0rem",
      width: "32rem !important",
    }
  },



})


const latestBlocks = [
  {
    id: "33951846",
    time1: "14",
    address: "0xf0245f6251bef9447a0...",
    txns: "71",
    time2: "2",
    BlockReward: "0.62868 MATIC",
  },
  {
    id: "33951846",
    time1: "14",
    address: "0xf0245f6251bef9447a0...",
    txns: "71",
    time2: "2",
    BlockReward: "0.62868 MATIC",
  },
  {
    id: "33951846",
    time1: "14",
    address: "0xf0245f6251bef9447a0...",
    txns: "71",
    time2: "2",
    BlockReward: "0.62868 MATIC",
  },
  {
    id: "33951846",
    time1: "14",
    address: "0xf0245f6251bef9447a0...",
    txns: "71",
    time2: "2",
    BlockReward: "0.62868 MATIC",
  },
  {
    id: "33951846",
    time1: "14",
    address: "0xf0245f6251bef9447a0...",
    txns: "71",
    time2: "2",
    BlockReward: "0.62868 MATIC",
  },
  {
    id: "33951846",
    time1: "14",
    address: "0xf0245f6251bef9447a0...",
    txns: "71",
    time2: "2",
    BlockReward: "0.62868 MATIC",
  },
  {
    id: "33951846",
    time1: "14",
    address: "0xf0245f6251bef9447a0...",
    txns: "71",
    time2: "2",
    BlockReward: "0.62868 MATIC",
  },
  {
    id: "33951846",
    time1: "14",
    address: "0xf0245f6251bef9447a0...",
    txns: "71",
    time2: "2",
    BlockReward: "0.62868 MATIC",
  },

]

const latestTransactions = [
  {
    address: "0x12f410257c9f9...",
    Time: "14",
    FromAddress: "0x5F0719717497898948...",
    ToAddress: "0xD86131bb631616675F...",
    BlockReward: "0 MATIC",
  },
  {
    address: "0x12f410257c9f9...",
    Time: "14",
    FromAddress: "0x5F0719717497898948...",
    ToAddress: "0xD86131bb631616675F...",
    BlockReward: "0 MATIC",
  },
  {
    address: "0x12f410257c9f9...",
    Time: "14",
    FromAddress: "0x5F0719717497898948...",
    ToAddress: "0xD86131bb631616675F...",
    BlockReward: "0 MATIC",
  },
  {
    address: "0x12f410257c9f9...",
    Time: "14",
    FromAddress: "0x5F0719717497898948...",
    ToAddress: "0xD86131bb631616675F...",
    BlockReward: "0 MATIC",
  },
  {
    address: "0x12f410257c9f9...",
    Time: "14",
    FromAddress: "0x5F0719717497898948...",
    ToAddress: "0xD86131bb631616675F...",
    BlockReward: "0 MATIC",
  },
  {
    address: "0x12f410257c9f9...",
    Time: "14",
    FromAddress: "0x5F0719717497898948...",
    ToAddress: "0xD86131bb631616675F...",
    BlockReward: "0 MATIC",
  },
  {
    address: "0x12f410257c9f9...",
    Time: "14",
    FromAddress: "0x5F0719717497898948...",
    ToAddress: "0xD86131bb631616675F...",
    BlockReward: "0 MATIC",
  },
  {
    address: "0x12f410257c9f9...",
    Time: "14",
    FromAddress: "0x5F0719717497898948...",
    ToAddress: "0xD86131bb631616675F...",
    BlockReward: "0 MATIC",
  },


]


const Transactions = () => {

  const [viewBtn, setViewBtn] = React.useState(false);
  const classes = styles();
  const history = useHistory();

  return (
    <div>
      <Box>
        <img src='images/header.png' style={{ width: '100%' }}></img>
      </Box>
      <Grid xs={12} style={{ display: 'flex', flexDirection: 'row', position: 'absolute', width: '80%', marginTop: '-5rem', gridGap: '50px', marginLeft: '10%' }}>
        <Grid xs={3} style={{ backgroundColor: '#fff', display: 'flex', padding: '4rem', border: '1px solid rgba(0, 0, 0, 0.2)', borderRadius: '5px' }}></Grid>
        <Grid xs={3} style={{ backgroundColor: '#fff', display: 'flex', padding: '4rem', border: '1px solid rgba(0, 0, 0, 0.2)', borderRadius: '5px' }}></Grid>
        <Grid xs={3} style={{ backgroundColor: '#fff', display: 'flex', padding: '4rem', border: '1px solid rgba(0, 0, 0, 0.2)', borderRadius: '5px' }}></Grid>
        <Grid xs={3} style={{ backgroundColor: '#fff', display: 'flex', padding: '4rem', border: '1px solid rgba(0, 0, 0, 0.2)', borderRadius: '5px' }}></Grid>

      </Grid>
      <Box style={{ marginTop: '10rem' }}>
        <Container maxWidth="" className={classes.container} style={{ maxWidth: '1400px' }}>

          <Grid container spacing={3} className={classes.maingrid} >

            {/* Latest Blocks */}
            <Box style={{ paddingTop: '0px', paddingLeft: '0px', width: '40rem', gap: '1rem' }} className={classes.box1}>
              <TableContainer component={Paper} className={classes.maintable} style={{ borderRadius: '10px' }}>
                {/* Latest Blocks Heading Typo */}
                <Table className={classes.table} style={{ border: "1px solid #D9D9D9" }} aria-label="simple table">

                  <TableRow className={classes.box}>
                    <TableCell className={classes.text} >
                      Latest Blocks
                    </TableCell>

                  </TableRow>
                  <div style={{ borderBottom: '1px solid grey' }}></div>
                  {/* Inner details */}
                  <TableBody>
                    {latestBlocks.map((values) => {
                      return (
                        <>
                          <Grid>
                            <Grid
                              xs={12}
                              style={{ display: "flex", flexDirection: "row", padding: '1rem' }}
                            >
                              <Grid
                                xs={4}
                                style={{ display: "flex", flexDirection: "row", gap: '0.5rem' }}
                              >
                                <Grid xs={5} style={{ width: '30px', height: '35px', }}>
                                  <Typography style={{ padding: '0.5rem', textAlign: 'center', backgroundColor: '#EEEEEE', fontSize: '12px' }}>Bk</Typography>
                                </Grid>
                                <Grid xs={7}>
                                  <Typography style={{ color: "#6495ED", fontSize: '14px' }}
                                    onClick={() => {
                                      history.push("/Block-details");
                                    }}>
                                    {" "}
                                    {values.id}
                                  </Typography>
                                  <Typography style={{ fontSize: '10px' }}>13 seconds ago</Typography>
                                </Grid>
                              </Grid>

                              <Grid xs={6}>
                                <Typography style={{ fontSize: '12px' }}>
                                  Validated By{" "}
                                  <span style={{ color: "#6495ED", fontSize: '12px' }}>0x02f701...</span>
                                  <br />
                                  <span style={{ marginRight: '3rem', fontSize: '12px' }}>87 txns in 2 secs</span>
                                </Typography>
                              </Grid>
                              <Grid xs={4}>
                                {" "}
                                <Typography className={classes.matic}
                                  style={{
                                    fontSize: '12px'
                                  }}
                                >
                                  {values.BlockReward}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Divider style={{ color: '1px solid rgba(54, 54, 54, 0.2)' }} />
                        </>
                      )
                    }
                    )}


                    {/* View all blocks Btn */}
                    <div style={{ borderBottom: '1px solid grey' }}></div>
                    <Box
                      style={{
                        border: "1px solid #e7eaf3",
                      }}
                    >
                      <Grid style={{ padding: '0.5rem' }}>
                        <Button
                          style={{
                            color: "#fff",
                            width: "100%",
                            backgroundColor: "#1976D2",
                          }}

                        >
                          {" "}
                          View all blocks
                        </Button>
                      </Grid>
                    </Box>
                  </TableBody>
                </Table>
              </TableContainer>

            </Box>
            {/* Latest Transactions */}
            <Box style={{ paddingTop: '0px', paddingLeft: '0px', width: '40rem' }} className={classes.box2}>
              <TableContainer component={Paper} className={classes.maintable} style={{ borderRadius: '10px' }}>
                {/* Latest Blocks Heading Typo */}
                <Table className={classes.table} style={{ border: "1px solid #D9D9D9" }} aria-label="simple table">

                  <TableRow className={classes.box}>
                    <TableCell className={classes.text} >
                      Latest Transactions
                    </TableCell>

                  </TableRow>
                  <div style={{ borderBottom: '1px solid grey' }}></div>
                  <TableBody>
                    {latestTransactions.map((values) => {
                      return (
                        <>
                          <Grid>
                            <Grid
                              xs={12}
                              style={{ display: "flex", flexDirection: "row", padding: '1rem' }} className={classes.grid2}
                            >
                              <Grid
                                xs={4}
                                style={{ display: "flex", flexDirection: "row", gap: '0.5rem' }}
                              >
                                <Grid xs={5}>
                                  <Typography style={{ padding: '0.5rem', textAlign: 'center', backgroundColor: '#EEEEEE', fontSize: '12px' }}>Tx</Typography>
                                </Grid>
                                <Grid xs={10}>
                                  <Typography style={{ fontSize: '12px' }}>
                                    {" "}
                                    <span
                                      style={{ color: "#6495ED" }}
                                      onClick={() => {
                                        history.push("/Transaction-details");
                                      }}
                                    >
                                      {values.address}</span>
                                    <br />
                                    <span>{values.Time} secs ago</span>
                                  </Typography>
                                </Grid>
                              </Grid>



                              <Grid xs={5} >
                                <Typography style={{ fontSize: '12px' }}>
                                  From{" "}
                                  <span style={{ color: "#6495ED", fontSize: '12px' }}>{values.FromAddress}</span>
                                </Typography>
                                <Typography style={{ marginRight: '1.4rem', fontSize: '12px' }}>
                                  To{" "}
                                  <span style={{ color: "#6495ED", fontSize: '12px' }}>{values.ToAddress}</span>
                                </Typography>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <Typography className={classes.matic}>

                                  {values.BlockReward}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Divider />
                        </>
                      )
                    }
                    )}

                    <div style={{ borderBottom: '1px solid grey' }}></div>
                    <Box
                      style={{
                        border: "1px solid #e7eaf3",
                      }}
                    >
                      <Grid style={{ padding: '0.5rem' }}>
                        <Button
                          style={{
                            hover: "#6495ED",
                            color: "#fff",
                            width: "100%",
                            backgroundColor: "#1976D2",
                          }}

                        >
                          {" "}
                          View all blocks
                        </Button>
                      </Grid>
                    </Box>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

          </Grid>

        </Container>
      </Box>

      {viewBtn ? (<TransactionDetails />) : ""}
      <Bottom />
    </div>
  )
}

export default Transactions
