import React from "react";
import { useHistory } from "react-router-dom";

import {
  Container,
  Box,
  Typography,
  Grid,
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableCell,
  Divider,
  TableBody,
  Button,
  styled

} from "@material-ui/core";
import { createUseStyles } from "react-jss";
import Bottom from "./footer";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "Left",
  display: "flex",
  border: "0.1px solid rgba(54, 54, 54, 0.2)"
  // color: theme.palette.text.secondary,
}));

const styles = createUseStyles({
  box: {
    // border: "1px solid rgba(54, 54, 54, 0.2)",
    textAlign: "left",
  },
  outergrid: {
    // border: "1px solid rgba(54, 54, 54, 0.2)",
    //padding:'inherit !important'
  },
  text: {
    align: "left !important",
    marginLeft: "2%",
    marginTop: "1%",
    fontSize: "20px !important",
    fontWeight: "800 !important",
    color: " rgb(25, 118, 210) !important",
    border: "1px solid rgba(54, 54, 54, 0.2)",
  },
  matic: {
    backgroundColor: "rgba(119,131,143,.1)",
    position: "relative",
    paddingLeft: "1.15rem",
    letterSpacing: ".8px",
    fontSize: ".875rem",
  },
  maingrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  successBtn: {
    backgroundColor: "#1E7500",
    color: "white",
    padding: "5px",
    borderRadius: "5px",
  },
  spanStyle: {
    color: "#1976D2",
    paddingTop: "5px"
  },
});

const Data = [
  {
    TransactionHash: "0x12f410257c9f93e2ed23fa2a8f749e0ed6e890cdf5ad7bf55083d1531d89f739",
    Status: "Success",
    Block1: "33951846",
    Block2: "4628 Block Confirmations",
    Timestamp: "2 hrs 43 mins ago (Oct-05-2022 06:49:40 AM +UTC)",
    From: "0x5f0719717497898948499aeaab9ba1826cf91dba",
    To: "0xd86131bb631616675fb80b67e2c9e0bc7eaf2d54",
    Value: " ($0.00)",
    TransactionF: "0.009271440005562864 MATIC ($0.01)",
    TransactionT: "2 (EIP-1559)"
  },
];

const TransactionDetails = () => {
  const classes = styles();
  const history = useHistory();
  return (
    <>
      <Grid style={{ margin: "5rem 10rem", backgroundColor: "#fff" }}>
        <Box style={{ paddingBottom: "1rem", textAlign: "left" }}>
          <h2>Transaction Details</h2>
        </Box>
        <TableContainer component={Paper} style={{ borderRadius: '10px' }}>

          <Table className={classes.table} style={{ border: "1px solid rgba(54, 54, 54, 0.2)" }} aria-label="simple table">
            <TableRow className={classes.box}>
              <TableCell className={classes.text}>
                Overview
              </TableCell>
            </TableRow>


            <TableBody>
              <Grid>
                <Grid xs={12}
                  style={{ display: "flex", flexDirection: "row", }}
                >

                  <Grid xs={4}>
                    <Item><Box>Transaction Hash:</Box></Item>
                    <Item><Box style={{ padding: "5px" }}>Status:</Box></Item>
                    <Item><Box style={{ padding: "5px" }}>Block:</Box></Item>
                    <Item><Box>Timestamp:</Box></Item>
                    <Item><Box>From:</Box></Item>
                    <Item><Box>Interacted With (To):</Box></Item>
                    <Item><Box>Value:</Box></Item>
                    <Item><Box>Transaction Fee:</Box></Item>
                    <Item><Box>Transaction Type:</Box></Item>
                  </Grid>


                  <Grid xs={8}>
                    {Data.map((value) => {
                      return (
                        <>
                          <Grid>
                            <Item>
                              {value.TransactionHash}
                            </Item>
                            {/* <Divider
              style={{ border: "0.2px solid grey" }}
            ></Divider> */}
                            <Item>
                              <Box className={classes.successBtn}>{value.Status}</Box>
                            </Item>
                            <Item>
                              <span className={classes.spanStyle}>{value.Block1}</span>
                              <Box style={{ backgroundColor: "#9F9F9F", color: "white", padding: "5px 10px", borderRadius: "5px", marginLeft: "10px" }}>{value.Block2}</Box>
                            </Item>
                            <Item>{value.Timestamp}</Item>
                            <Item style={{ color: "#1976D2" }}>{value.From}</Item>
                            <Item>Contract
                              <span style={{ color: "#1976D2", paddingLeft: "5px" }}>{value.To}</span>
                              {/* <CheckCircleIcon fontSize="small" style={{color:"green"}}/> */}
                            </Item>
                            <Item>{value.Value}</Item>
                            <Item>{value.TransactionF}</Item>
                            <Item>{value.TransactionT}</Item>
                          </Grid>
                        </>
                      )
                    })}
                  </Grid>

                </Grid>
              </Grid>


            </TableBody>
          </Table>

        </TableContainer>
      </Grid>
      <Bottom />
    </>
  );
};
export default TransactionDetails;
