import  {lazy} from 'react';

import HomeLayout from  "./layouts/HomeLayout";
import DashboardLayout from './layouts/HomeLayout/DashboardLayout';
  
export const routes = [
    {
      exact: true,
      path: "/",
      layout: HomeLayout,
      component: lazy(() => import("./views/pages/Home")),
    },
    {
        exact:true,
        path:"/Transaction-details",
        layout: DashboardLayout,
        component: lazy(() => import("./component/TransactionDetails")),
  },
  {
    exact: true,
    path: "/Block-details",
    layout: DashboardLayout,
    component: lazy(() => import("./component/BlockDetails")),
  }
]