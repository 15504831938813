import React, { Suspense, Fragment } from "react";
import { BrowserRouter, Route,Switch} from "react-router-dom";
import PageLoading from "./component/PageLoading";
import { routes } from "./routes";
import { createBrowserHistory } from "history";
 
const history = createBrowserHistory();

function App() {

  return (
    <div className="App">
      <BrowserRouter history={history}>
        <RenderRoutes data={routes} />
      </BrowserRouter>
    </div>
  );
}
export default App;

function RenderRoutes(props) { 
  
  return (
    <Suspense fallback={<PageLoading />}>
      <Switch>
        {console.log("data of ",props.data)}
        {props.data.map((route, i) => {
          console.log("routet",route)
          console.log("key",i)
          const Component = route.component;
          const Layout = route.layout || Fragment;
          return (
            <Route
              exact={route.exact}
              key={i}
              path={route.path}
              render={(props) => (
                  <Layout>
                    {route.routes ? (
                
                       
                      <RenderRoutes data={route.routes} />
              
                    ) : ( 
            
          
                    <Component {...props}/>
                
                    )}
                  </Layout>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
}

