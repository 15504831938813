import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';
import TopBar from "../TopBar";
import { useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundRepeat: "no-repeat",
//     background: "#0F0F0F",
//     backgroundSize: "100%",
//     backgroundPosition: "top",
//     display: "flex",
//     height: "100%",
//     overflow: "hidden",
//     width: "100%",
//   },
//   wrapper: {
//     display: "flex",
//     flex: "1 1 auto",
//     overflow: "hidden",
//     paddingTop: 70,
//     minHeight: "100vh",
//     [theme.breakpoints.up("lg")]: {
//       paddingLeft: 256,
//     },
//   },
//   contentContainer: {
//     display: "flex",
//     flex: "1 1 auto",
//     overflow: "hidden",
//     backgroundRepeat: "no-repeat",
//     // backgroundColor: "rgba(204, 204, 204, 0)",
//     // backgroundImage: "url(/images/line.png)" /* fallback */,
//     backgroundSize: "100%",
//     // background: "#F4FCFA",
//     backgroundPosition: "top",
//     // backgroundImage:
//     //   " url(/images/line.png), linear-gradient(105deg, #feeefd 1.25%, #4606b9 99.18%)" /* W3C */,
//   },
//   content: {
//     flex: "1 1 auto",
//     height: "100%",
//     overflow: "hidden",
//     position: "relative",
//     WebkitOverflowScrolling: "touch",
//     padding: "10px 50px 0px ",
//     [theme.breakpoints.down("sm")]: {
//       padding: "10px 20px 0px ",
//     },
//   },
}));

const DashboardLayout = ({ children }) => {

  console.log("Children of dashboard",children)

  const classes = useStyles();
  
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className={classes.root}>
      <TopBar/>
      <div
        style={
          history.location.pathname !== "/"
            ? { display: "block" }
            : { display: "none" }
        }
      ></div>
      <div>
         {children}
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default DashboardLayout;