import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import TopBar from "./TopBar";
import Transactions from "../../component/Transactions"

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
  },
}));

const MainLayout = ({children}) => {
    const classes = useStyles();
    return (
      <div className={classes.root}>
        <TopBar/>
        <Transactions/>
      </div>
    );
  };
  
  export default MainLayout;
  